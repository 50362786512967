import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import * as React from 'react';
export default {
  FontAwesomeIcon,
  faTwitter,
  faLinkedin,
  faGithub,
  faEnvelope,
  faGlobe,
  OutboundLink,
  React
};